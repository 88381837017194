
import { QFile } from "quasar";
import { Component, Mixins, Ref } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import FormValidator from "@/mixins/FormValidator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import RequestDetailForm from "@/components/forms/RequestDetailForm.vue";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
import LabelHelpers from "@/mixins/LabelHelpers";
// @ts-ignore-end

interface requestData {
  document_type: string;
  document_id: string;
  ticket_number: string;
}

interface historyData {
  id: string;
  message: string;
  files: any;
}

interface editData {
  id: string;
  message: string;
  files: any;
  address: any;
}

interface cancelData {
  id: string;
  motive: string | null;
  message: string;
}

@Component({
  components: {
    FormCard,
    RequestDetailForm,
    LogoSingle,
    colores
  }
})
export default class RequestDetailsNew extends Mixins(
  FormValidator,
  LabelHelpers
) {
  @Ref("file_input") file_input!: QFile;

  protected formData: requestData = {
    document_type: "",
    document_id: "",
    ticket_number: ""
  };

  protected formHistory: historyData = {
    id: "",
    message: "",
    files: null
  };

  protected formEdit: editData = {
    id: "",
    message: "",
    files: null,
    address: {
      street: "",
      number: "",
      hint: ""
    }
  };

  protected formCancel: cancelData = {
    id: "",
    motive: null,
    message: ""
  };

  private ticket = {
    id: "",
    id_motivo: "",
    id_neighbor: "",
    correlativo: "",
    message: "",
    message_original: "",
    current_status: "",
    date: "",
    service: "",
    enable_edit: null,
    motive: "",
    default_address: null,
    files: [],
    history: [],
    direccion: {
      calle: "",
      numero: "",
      aclaratoria: ""
    }
  };

  private user = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    rut: "",
    email: "",
    document_type: ""
  };

  private ruta = "";
  private reserva: any = [];
  private ocultar: Boolean = false;
  private cancel_ticket: Boolean = false;
  private cancel_ticket_v2: Boolean = false;
  private tipoReserva: Boolean = false;
  private count_retrieved: any = false;
  private historialTicket: any = 0;
  private current_archives: Array<any> = [];

  protected downloading_file = false;
  protected download_progress = 0;

  enable_edit: boolean = false;
  enable_history: boolean = false;
  view_response: boolean = false;

  dialog_edit: boolean = false;
  dialog_history: boolean = false;
  id_historial: any = null;
  selected_historial: any = null;

  options_street: any = [];
  streets: any[] = [];
  street_search_empty = false;
  btn_edit: boolean = false;

  motives: any[] = [];
  btn_cancel: boolean = false;

  toolbal = [
    [
      {
        icon: this.$q.iconSet.editor.align,
        fixedLabel: true,
        list: "only-icons",
        options: ["left", "center", "right", "justify"]
      }
    ],
    [
      {
        icon: this.$q.iconSet.editor.bold,
        options: ["bold", "italic", "strike", "underline"]
      }
    ],
    ["link"],
    [
      {
        label: "Fuente",
        icon: this.$q.iconSet.editor.font,
        list: "no-icons",
        options: ["Principal"]
      }
    ],
    [
      {
        label: this.$q.lang.editor.fontSize,
        icon: this.$q.iconSet.editor.fontSize,
        fixedLabel: true,
        fixedIcon: true,
        list: "no-icons",
        options: ["size-1", "size-2", "size-3", "size-4", "size-5"]
      }
    ]
  ];

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private confirmCancel() {
    this.$q.loading.show();
    this.$axios
      .get("motivo-cancelars/listar")
      .then(response => {
        this.motives = response.data;
        this.cancel_ticket = true;
      })
      .catch(error => {
        this.notify(this.$t("notification.problem") as string);
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private attempCancel() {
    if (this.checkCharCount() === false) {
      return this.notify("El texto no puede exceder los 900 carácteres");
    }
    this.$q.loading.show();
    this.btn_cancel = true;
    //
    this.$axios
      .delete("ticket/cancelar", {
        params: {
          id: this.ticket.id,
          id_motivo: this.formCancel.motive,
          tipo: this.tipoReserva,
          mensaje: this.formCancel.message
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$router.go(0);
        this.notify("Se ha cancelado la solicitud");
      })
      .catch(error => {
        this.notify("Ha ocurrido un error");
      })
      .finally(() => {
        this.$q.loading.hide();
        this.btn_cancel = false;
      });
  }

  private getTicket() {
    this.$q.loading.show();
    this.$axios
      .get("tickets/detalle", {
        params: {
          rut: this.formData.document_id,
          id_ticket: this.formData.ticket_number
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.setTicketData(response.data);
        this.setUserData(response.data.persona);
        this.ocultar = true;
        this.countHistorial();
      })
      .catch(error => {
        if (error.response.data.error.message == "ticket_no_data") {
          this.notify("No se ha encontrado el ticket");
        }
        this.$router.push({ name: "consultar" });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private htmlToString(html: string) {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  private validateImage(files: any) {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size) {
          if (Number(files[i].size) > 51 * 1024 * 1024) {
            return "El tamaño del documento no debe ser mayor a 50 Mb";
          }
        }
      }
    }
    return "";
  }

  private updateFiles(files: any) {
    if (files) {
      const validate = this.validateImage(files);
      if (validate != "") return this.notify(validate);
    }
    if (Array.isArray(files) === false || files.length === 0) {
      this.formHistory.files = null;
    } else if (Array.isArray(this.formHistory.files) === true) {
      const diff = this.formHistory.files.filter(
        (file: any) => files.indexOf(file) === -1
      );

      if (diff.length === 1 && this.formHistory.files.length > 1) {
        this.formHistory.files = files.slice();
      } else {
        this.formHistory.files = diff.concat(files);
      }
    } else {
      this.formHistory.files = files.slice();
    }
  }

  private clearFile(index: number) {
    this.formHistory.files.splice(index, 1);
  }

  private clearEditFile(index: number) {
    this.formEdit.files.splice(index, 1);
    this.current_archives.splice(index, 1);
  }

  private subirArchivo() {
    this.file_input.pickFiles();
  }

  private checkCharCount(): boolean {
    let length = this.htmlToString(this.formHistory.message).length;
    if (length > 4000) {
      return false;
    }
    return true;
  }

  private updateEditFiles(files: any) {
    if (files) {
      const validate = this.validateImage(files);
      if (validate != "") return this.notify(validate);
    }
    if (Array.isArray(files) === false || files.length === 0) {
      this.formEdit.files = null;
    } else if (Array.isArray(this.formEdit.files) === true) {
      const diff = this.formEdit.files.filter(
        (file: any) => files.indexOf(file) === -1
      );
      if (diff.length === 1 && this.formEdit.files.length > 1) {
        this.formEdit.files = files.slice();
      } else {
        this.formEdit.files = diff.concat(files);
      }
    } else {
      this.formEdit.files = files.slice();
    }
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private getCalles() {
    this.$axios
      .get("calles", {
        params: {
          filter: {
            where: { estado: 1 },
            fields: ["id", "nombre", "estado"],
            order: "nombre ASC"
          }
        }
      })
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {});
  }

  private clearFormEdit() {
    this.formEdit.id = "";
    this.formEdit.message = "";
    this.formEdit.files = null;
    this.formEdit.address.street = "";
    this.formEdit.address.number = "";
    this.formEdit.address.hint = "";
  }

  private currentDocuments() {
    // vacias documentos adjuntos
    this.current_archives = [];
    // check si hay documentos adjuntos
    if (this.ticket.files && this.ticket.files.length > 0) {
      for (let i = 0; i < this.ticket.files.length; i++) {
        // agregar archivo (falso) a los adjuntos del front
        const file = {
          lastModified: 10500,
          name: (this.ticket.files[i] as any).original,
          size: 10500,
          type: "",
          webkitRelativePath: ""
        };
        this.updateEditFiles([file]);
        // agregar documentos actuales
        this.current_archives.push((this.ticket.files[i] as any).id as number);
      }
    }
  }

  private enableEdit() {
    this.getCalles();
    this.clearFormEdit();
    this.currentDocuments();
    this.formEdit.id = this.ticket.id;
    this.formEdit.message = this.ticket.message_original;
    this.formEdit.address.street = this.ticket.direccion.calle;
    this.formEdit.address.number = this.ticket.direccion.numero;
    this.formEdit.address.hint = this.ticket.direccion.aclaratoria;
    this.dialog_edit = true;
  }

  private submitAttempEdit() {
    if (
      this.formEdit.message.trim() == "" ||
      this.formEdit.message == "&nbsp;"
    ) {
      return this.notify("Debe ingresar un mensaje");
    }
    if (this.checkCharCount() === false) {
      return this.notify("El texto no puede exceder los 900 carácteres");
    }
    this.$q.loading.show();
    this.btn_edit = true;
    let adjuntos_input = document.getElementsByName("Files");
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") adjuntos_empty = false;
    }
    if (adjuntos_empty) adjuntos = null;
    const formEdit = new FormData();
    if (this.formEdit.files) {
      this.formEdit.files.forEach((item: any, index: any) =>
        formEdit.append("my-file-" + index, item)
      );
    }
    this.$axios
      .post("tickets/editar", formEdit, {
        params: {
          id_ticket: this.formEdit.id,
          mensaje: this.formEdit.message,
          calle: this.formEdit.address.street,
          numero: this.formEdit.address.number,
          aclaratoria: this.formEdit.address.hint,
          archivos: this.current_archives
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.notify("Datos editados correctamente");
        this.dialog_edit = false;
        this.getTicket();
      })
      .catch(error => {
        let message = "Ha ocurrido un error";
        switch (error.response.data.error.message) {
          case "ticket_no_data":
            message = "El ticket actual no existe";
            break;
          case "invalid_ticket":
            message = "El ticket ya no esta habilitado para editar";
            break;
          case "invalid_user":
            message = "Debe acceder a su cuenta para editar su solicitud";
            break;
          case "empty_mensaje":
            message = "Debe completar el campo mensaje";
            break;
          case "empty_street":
            message = "Debe completar el campo calle";
            break;
          case "empty_number":
            message = "Debe completar el campo número";
            break;
          case "upload_error":
            message =
              "Hubo un error subiendo los archivos, vuelva a intentarlo";
            break;
        }
        this.notify(message);
      })
      .finally(() => {
        this.$q.loading.hide();
        this.btn_edit = false;
      });
  }

  private enableResponse(history: any) {
    this.clearFormHistory();
    this.id_historial = history.id;
    this.dialog_history = true;
  }

  private viewResponse(history: any) {
    this.selected_historial = history;
    this.view_response = true;
  }

  private clearFormHistory() {
    this.formHistory.id = "";
    this.formHistory.message = "";
    this.formHistory.files = null;
  }

  private submitAttempResponse() {
    if (
      this.formHistory.message.trim() == "" ||
      this.formHistory.message == "&nbsp;"
    ) {
      return this.notify("Debe ingresar un mensaje");
    }
    if (this.checkCharCount() === false) {
      return this.notify("El texto no puede exceder los 900 carácteres");
    }
    this.$q.loading.show();

    let adjuntos_input = document.getElementsByName("Files");
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") adjuntos_empty = false;
    }
    if (adjuntos_empty) adjuntos = null;
    const formHistory = new FormData();
    if (this.formHistory.files) {
      this.formHistory.files.forEach((item: any, index: any) =>
        formHistory.append("my-file-" + index, item)
      );
    }
    this.$axios
      .post("ticket-historial-respuestas/frontoffice/responder", formHistory, {
        params: {
          id_historial: Number(this.id_historial),
          mensaje: this.formHistory.message
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.notify("Mensaje enviado correctamente");
        this.dialog_history = false;
        this.getTicket();
        this.clearFormHistory();
      })
      .catch(error => {
        let message = "Ha ocurrido un error";
        switch (error.response.data.error.message) {
          case "respuesta_no_data":
            message = "La respuesta no está habilitada ";
            break;
          case "empty_mensaje":
            message = "Debe completar el campo mensaje";
            break;
          case "upload_error":
            message =
              "Hubo un error subiendo los archivos, vuelva a intentarlo";
            break;
          case "invalid_ticket":
            message = "No es posible responder. La solicitud fue cerrada";
            break;
        }
        this.notify(message);
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private countHistorial() {
    this.$axios
      .get("ticket-historials/count", {
        params: {
          where: {
            and: [{ id_ticket: Number(this.ticket.id) }, { estado: 1 }]
          }
        }
      })
      .then(response => {
        this.historialTicket = response.data.count;
        this.count_retrieved = true;
      })
      .catch(error => {
        this.notify("No se ha encontrado el ticket");
      });
  }

  private setUserData(user: any) {
    this.user.name = user.nombre;
    this.user.father_last_name = user.apellido_paterno;
    this.user.mother_last_name = user.apellido_materno;
    this.user.rut = user.rut;
    this.user.email = user.email;
    this.user.document_type = user.tipo_documento.toString();
  }

  private setTicketData(ticket: any) {
    (this.ticket.direccion.calle = ticket.direccion.calle),
      (this.ticket.direccion.numero = ticket.direccion.numero),
      (this.ticket.direccion.aclaratoria = ticket.direccion.aclaratoria),
      (this.ticket.correlativo = ticket.correlativo);
    this.ticket.id = ticket.id;
    this.ticket.id_motivo = ticket.id_motivo;
    this.ticket.id_neighbor = ticket.id_persona;
    this.ticket.message = ticket.mensaje;
    this.ticket.message_original = ticket.dispositivo;
    this.ticket.current_status = ticket.estado_ticket;
    this.ticket.date = ticket.fecha;
    this.ticket.service = ticket.servicio
      ? ticket.servicio.nombre
      : "No tiene servicio";
    this.ticket.enable_edit = ticket.enable_edit;
    this.ticket.motive = ticket.motivo.nombre
      ? ticket.motivo.nombre
      : "Sin información";
    (this.ticket.history as any) = [];
    if (ticket.servicio) {
      if (ticket.servicio.direccion_default) {
        this.ticket.default_address = ticket.servicio.direccion_default;
      }
    }
    this.ticket.files = [];
    if (ticket.ticket_adjunto) {
      for (let i = 0; i < ticket.ticket_adjunto.length; i++) {
        let file = {
          id: ticket.ticket_adjunto[i].id,
          original: ticket.ticket_adjunto[i].original
        };
        (this.ticket.files as any).push(file);
      }
    }
    if (ticket.ticket_historial) {
      for (let i = 0; i < ticket.ticket_historial.length; i++) {
        if (ticket.ticket_historial[i]) {
          let response = false;
          let responsed = false;
          let data_response = null;
          if (ticket.ticket_historial[i].ticket_historial_respuesta) {
            if (
              ticket.ticket_historial[i].ticket_historial_respuesta.length > 0
            ) {
              if (
                ticket.ticket_historial[i].ticket_historial_respuesta[0]
                  .fecha_respuesta == null
              ) {
                // si no hay fecha respuesta, hay una pregunta pendiente
                response = true;
              } else {
                // si hay fecha respuesta, fue respondido
                responsed = true;
                data_response =
                  ticket.ticket_historial[i].ticket_historial_respuesta[0];
              }
            }
          }
          let history = {
            id: ticket.ticket_historial[i].id,
            status: ticket.ticket_historial[i].estado_ticket,
            status_name: ticket.ticket_historial[i].ticket_estado.nombre,
            message: ticket.ticket_historial[i].mensaje,
            date: ticket.ticket_historial[i].fecha,
            attachment: ticket.ticket_historial[i].ticket_historial_adjunto,
            response: response,
            responsed: responsed,
            data_response: data_response
          };
          (this.ticket.history as any).push(history);
        }
      }
    }
    if (ticket.reserva) {
      this.reserva = ticket.reserva;
      this.tipoReserva = true;
    } else {
      this.reserva = [];
      this.tipoReserva = false;
    }
  }

  private extension(filename: any) {
    var extension = filename.split(".").pop();
    return extension;
  }

  private name(filename: any) {
    let name = filename.substr(0, filename.lastIndexOf(".")) || filename;
    name = name.replaceAll("_", " ");
    return name;
  }

  protected updateProgressFromChild(value: number) {
    this.download_progress = value;
  }

  protected get progressLabel() {
    return Math.round(this.download_progress * 100).toString() + "%";
  }

  private download(id: number, nombre: string) {
    if (this.downloading_file) {
      return this.notify("Solo puede descargar un archivo a la vez");
    }
    this.downloading_file = true;
    this.notify(this.$t("notification.download_start") as string);
    this.$axios
      .get("ticket-adjuntos/bajar/" + id.toString(), {
        params: {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.notify(this.$t("notification.download_error") as string);
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private downloadHistory(id: number, nombre: string) {
    if (this.downloading_file) {
      return this.notify("Solo puede descargar un archivo a la vez");
    }
    this.downloading_file = true;
    this.notify(this.$t("notification.download_start") as string);
    this.$axios
      .get("ticket-historial-adjuntos/front/bajar/" + id.toString(), {
        params: {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.notify(this.$t("notification.download_error") as string);
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private downloadResponse(id: number, nombre: string) {
    if (this.downloading_file) {
      return this.notify("Solo puede descargar un archivo a la vez");
    }
    this.downloading_file = true;
    this.notify(this.$t("notification.download_start") as string);
    this.$axios
      .post(
        "ticket-historial-respuesta-adjuntos/bajar",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          },
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            this.download_progress =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) /
              100;
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        this.notify(this.$t("notification.download_error") as string);
      })
      .finally(() => {
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }

  private humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
    let newTime = date_time.split(" ")[1].split(":");

    return newTime[0] + ":" + newTime[1];
  }

  private humanDateTime(date: any) {
    let date_object = new Date(date);
    return date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
  }

  private cancelarTicket(idTicket: any) {
    this.$q.loading.show();
    this.$axios
      .delete("ticket/cancelar", {
        params: {
          id: idTicket,
          id_motivo: this.formCancel.motive,
          tipo: this.tipoReserva,
          mensaje: this.formCancel.message
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$router.go(0);
        this.$q.loading.hide();
        this.notify("Se ha canceló la solicitud");
      })
      .catch(error => {
        this.$q.loading.hide();
        this.notify("Ha ocurrido un error");
      });
  }

  private mounted() {

    if (
      this.$router.currentRoute.params &&
      this.$router.currentRoute.params.id_ticket &&
      this.$router.currentRoute.params.rut &&
      this.$router.currentRoute.params.tipo_doc
    ) {
      this.formData.ticket_number = this.$router.currentRoute.params.id_ticket;
      this.formData.document_type = this.$router.currentRoute.params.tipo_doc;
      this.formData.document_id = this.$router.currentRoute.params.rut;
      if (this.$router.currentRoute.name == "requestdetailsnew") {
        this.ruta = "Detalle del Ticket";
      }
      this.getTicket();
    } else if (
      this.$router.currentRoute.params &&
      this.$router.currentRoute.params.id_ticket
    ) {
      this.formData.ticket_number = this.$router.currentRoute.params.id_ticket;

      if (
        this.$store.state.user.document_type && this.$store.state.user.document_type != "" && 
        this.$store.state.user.rut && this.$store.state.user.rut != ""
      ) {
        this.formData.document_type = this.$store.state.user.document_type;
        this.formData.document_id = this.$store.state.user.rut;
      } else if (
        this.$route.query.rut
      ) {
        this.formData.document_type = this.$route.query.tipo_doc as string;
        this.formData.document_id = this.$route.query.rut as string;
      }
      if (this.$router.currentRoute.name == "requestdetailsnew") {
        this.ruta = "Detalle de la Solicitud";
      }
      this.getTicket();
    }
  }
}
