
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import FormCard from "@/components/FormCard.vue";
interface requestData {
  document_type: string;
  document_id: string;
  ticket_number: string;
}

@Component({
  components: {
    FormCard
  }
})
export default class RequestDetailFormNew extends Mixins(FormValidator) {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  protected formData: requestData = {
    document_type: "1",
    document_id: "",
    ticket_number: ""
  };

  private mounted() {}

  formIsTicketNumber(value: string) {
    if (/^([0-9]+-[0-9]+)$|^[0-9]+$/g.test(value)) {
      return true;
    }
    return this.$t("El número del ticket no es válido");
  }

  /*private formatTicketForm() {
    this.formData.ticket_number = this.formData.ticket_number
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
  }*/

  private consultarTicket() {
    this.$router.push({
      name: "requestdetailsnew",
      params: {
        id_ticket: this.formData.ticket_number,
        rut: this.formData.document_id,
        tipo_doc: this.formData.document_type
      }
    });
  }
}
