
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import FormCard from "@/components/FormCard.vue";
import RequestDetailForm from "@/components/forms/RequestDetailForm.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
// @ts-ignore-end

@Component({
  components: {
    FormCard,
    RequestDetailForm,
    LogoSingle,
    colores
  }
})
export default class RequestDetails extends Mixins(FormValidator) {
  @Prop(Number) readonly id_ticket: number | undefined;
  @Prop({ default: false }) readonly quick_search!: boolean;

  private ticket = {
    number: "",
    message: "",
    current_status: "",
    date: "",
    service: "",
    files: [],
    history: []
  };

  private user = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    rut: "",
    email: "",
    document_type: ""
  };

  private text = "";
  private quality = 0;
  private submitResult: any;
  private disable_button = false;
  private can_valuate = false;
  private ruta = "";
  private alert: any = false;
  private mensaje = "";
  private reserva: any = [];

  private ticket_found: Boolean = false;

  protected downloading_file = false;
  protected download_progress = 0;
  private consulta: Boolean = false;

  private mounted() {
    if (this.$router.currentRoute.name == "requestdetails") {
      this.ruta = "Detalle del Ticket";
    }
  }

  protected onSubmit(evt: any) {
    const formData = new FormData(evt.target);
    const submitResult = [];

    for (const [name, value] of formData.entries()) {
      submitResult.push({
        name,
        value
      });
    }

    this.submitResult = submitResult;
    if (this.submitResult[0].value == 0) {
      this.$q.notify({
        message: "Debe seleccionar una calificación",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    } else {
      this.$axios
        .post("evaluacion-tickets", {
          id_evaluacion: parseInt(this.submitResult[0].value),
          id_ticket: this.ticket.number,
          comentario: this.text
        })
        .then(response => {
          this.$q.notify({
            message: "Se evaluó el servicio correctamente",
            color: "principal",
            position: "top",
            timeout: 5000
          });
          this.$router.push({ name: "myrequests" });
        })
        .catch(error => {
          this.$q.notify({
            message: "Ha ocurrido un error al evaluar el servicio",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
    }
  }

  private extension(filename: any) {
    var extension = filename.split(".").pop();
    return extension;
  }

  private name(filename: any) {
    let name = filename.substr(0, filename.lastIndexOf(".")) || filename;
    name = name.replaceAll("_", " ");
    return name;
  }

  protected updateProgressFromChild(value: number) {
    this.download_progress = value;
  }

  protected get progressLabel() {
    return Math.round(this.download_progress * 100).toString() + "%";
  }

  private yesTicket(e: any) {
    this.consulta = true;
  }

  private setFormData(data: any) {
    this.ticket_found = true;
    this.setTicketData(data);
    this.canValuate(data);
    this.setUserData(data.persona);

    if (data.reserva) {
      this.reserva = data.reserva;
    } else {
      this.reserva = [];
    }
  }

  private setUserData(user: any) {
    this.user.name = user.nombre;
    this.user.father_last_name = user.apellido_paterno;
    this.user.mother_last_name = user.apellido_materno;
    this.user.rut = user.rut;
    this.user.email = user.email;
    this.user.document_type = user.tipo_documento.toString();
  }

  private resetTicketData() {
    this.ticket.number = "";
    this.ticket.message = "";
    this.ticket.current_status = "";
    this.ticket.date = "";
    this.ticket.service = "";
    this.ticket.files = [];
    this.ticket.history = [];
  }

  private resetUserData() {
    this.user.name = "";
    this.user.father_last_name = "";
    this.user.mother_last_name = "";
    this.user.rut = "";
    this.user.email = "";
  }

  private resetData() {
    this.ticket_found = false;
    this.resetTicketData();
    this.resetUserData();
  }

  private canValuate(ticket: any) {
    if (ticket.estado_ticket === 6) {
      this.$axios
        .get("evaluacion-tickets/count", {
          params: {
            where: {
              id_ticket: this.ticket.number
            }
          }
        })
        .then(response => {
          if (response.data.count === 0) {
            this.can_valuate = true;
          } else {
            this.can_valuate = false;
          }
        })
        .catch(error => {
          this.$q.notify({
            message: "Ha ocurrido un error al evaluar el servicio",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
    }
  }

  private setTicketData(ticket: any) {
    this.ticket.number = ticket.id;
    this.ticket.message = ticket.mensaje;
    this.ticket.current_status = ticket.estado_ticket;
    this.ticket.date = ticket.fecha;
    this.ticket.service = ticket.servicio
      ? ticket.servicio.nombre
      : "No tiene servicio";
    if (ticket.ticket_adjunto) {
      for (let i = 0; i < ticket.ticket_adjunto.length; i++) {
        let file = {
          id: ticket.ticket_adjunto[i].id,
          original: ticket.ticket_adjunto[i].original
        };
        (this.ticket.files as any).push(file);
      }
    }
    if (ticket.ticket_historial) {
      for (let i = 0; i < ticket.ticket_historial.length; i++) {
        if (ticket.ticket_historial[i]) {
          let history = {
            status: ticket.ticket_historial[i].estado_ticket,
            message: ticket.ticket_historial[i].mensaje,
            date: ticket.ticket_historial[i].fecha,
            attachment: ticket.ticket_historial[i].ticket_historial_adjunto
          };
          (this.ticket.history as any).push(history);
        }
      }
    }
  }

  private download(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "principal",
      position: "top",
      timeout: 5000
    });
    this.$axios
      .get("ticket-adjuntos/bajar/" + id.toString(), {
        params: {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private downloadHistory(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "principal",
      position: "top",
      timeout: 5000
    });
    this.$axios
      .get("ticket-historial-adjuntos/front/bajar/" + id.toString(), {
        params: {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private respuesta(data: any) {
    this.alert = true;
    this.mensaje = data;
  }

  private goBack() {
    if (this.$store.state.logged) {
      this.$router.push({ name: "myrequests" });
    } else {
      this.resetData();
    }
  }

  private getStatus(status: number) {
    let status_name = "Desconocido";
    switch (status) {
      case 1:
        status_name = "Gestión";
        break;
      case 2:
        status_name = "Revisión";
        break;
      case 3:
        status_name = "Derivado";
        break;
      case 4:
        status_name = "VB Jefe";
        break;
      case 5:
        status_name = "VB Director";
        break;
      case 6:
        status_name = "Cerrado";
        break;
      case 9:
        status_name = "Despachado";
        break;
      case 10:
        status_name = "Novedad";
        break;
      case 13:
        status_name = "En Espera";
        break;
      default:
        status_name = "Desconocido";
        break;
    }
    return status_name;
  }

  private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getStateColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }

  private humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
    let newTime = date_time.split(" ")[1].split(":");

    return newTime[0] + ":" + newTime[1];
  }

  private humanDateTime(date: any) {
    let date_object = new Date(date);
    return date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
  }

  private formatRutShow(rut: any) {
    if (rut.replace("-", "").replace(/[.-]/g, "").length <= 7) {
      return rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      return rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }
}
